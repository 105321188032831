import React, { useState, useEffect, useContext, forwardRef } from "react";
import { DataTable } from "mantine-datatable";
import {
  Badge,
  Text,
  Box,
  useMantineTheme,
  Group,
  Button,
  Stack,
  createStyles,
  ThemeIcon,
  HoverCard,
  Divider,
} from "@mantine/core";
import sortBy from "lodash/sortBy";
import { mapContext } from "../context/mapContext.js";
import { useMediaQuery } from "@mantine/hooks";
import { Link, useLocation } from "react-router-dom";
import {
  IconArrowBadgeDown,
  IconArrowBadgeUp,
  IconArrowNarrowLeft,
  IconInfoCircle,
} from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";

const CONTENT_WIDTH = "calc(100% - 23px)";
const PROBLEM_COLOR = "#E03131";

const useStyles = createStyles((theme) => ({
  popupBox: {
    [theme.fn.largerThan("sm")]: {
      height: "calc(100% + 2px)",
      width: CONTENT_WIDTH,
    },
  },

  rcText: {
    color:
      theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.black,
  },
}));

export default function PopupContent() {
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "unit",
    direction: "asc",
  });
  const { popupAddress, popupUnits, popupOwner, popupMultipleOwners } =
    useContext(mapContext);
  const [sortedRecords, setRecords] = useState(sortBy(popupUnits, "unit"));
  const theme = useMantineTheme();
  const mediaQuery = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
  const { classes } = useStyles();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const data = sortBy(popupUnits, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
  }, [sortStatus, popupUnits]);

  const PopupOwner = ({ popupMultipleOwners, popupOwner }) => {
    if (popupMultipleOwners.current) {
      if (mediaQuery) {
        return <Text>{t("popup.multiple_owners_long")}</Text>;
      } else {
        return <Text>{t("popup.multiple_owners")}</Text>;
      }
    } else {
      return (
        <Box sx={{ maxWidth: CONTENT_WIDTH }}>
          <Text truncate>
            {t("popup.owner")}: <b>{popupOwner.current}</b>
          </Text>
        </Box>
      );
    }
  };

  const essential_columns = [
    {
      accessor: "unit",
      title: t("table_headers.unit"),
      textAlignment: "right",
      sortable: true,
      render: ({ unit }) => {
        return <Link to={unit.toString().concat(search)}>{unit}</Link>;
      },
    },
    {
      accessor: "rent",
      title: t("table_headers.rent"),
      textAlignment: "right",
      render: ({ rent }) =>
        new Intl.NumberFormat(i18n.language, {
          style: "currency",
          currency: "USD",
        }).format(rent),
      sortable: true,
    },
    {
      accessor: "rentdate",
      title: t("table_headers.date"),
      textAlignment: "right",
      sortable: true,
    },
    {
      accessor: "beds",
      title: t("table_headers.beds"),
      textAlignment: "right",
      sortable: true,
      hidden: !mediaQuery,
    },
    {
      accessor: "baths",
      title: t("table_headers.baths"),
      textAlignment: "right",
      sortable: true,
      hidden: !mediaQuery,
    },
    {
      accessor: "built",
      title: t("table_headers.built"),
      textAlignment: "right",
      sortable: true,
      hidden: !mediaQuery,
    },
    {
      accessor: "encumbered",
      title: t("table_headers.affordability"),
      textAlignment: "right",
      sortable: true,
      hidden: !mediaQuery,
      render: ({ encumbered }) => {
        if (encumbered === 1) {
          return <Badge color="lime">{t("chips.restricted")}</Badge>;
        } else {
          return <Badge>{t("chips.unrestricted")}</Badge>;
        }
      },
    },
    {
      accessor: "status",
      title: t("table_headers.vacancy"),
      textAlignment: "right",
      sortable: true,
      hidden: !mediaQuery,
      render: ({ status }) => {
        if (status === "Rented") {
          return <Badge>{t("chips.rented")}</Badge>;
        } else {
          return <Badge color="lime">{t("chips.vacant")}</Badge>;
        }
      },
    },
    {
      accessor: "owner",
      title: t("table_headers.owner"),
      textAlignment: "right",
      sortable: true,
      ellipsis: true,
      hidden: !(mediaQuery && popupMultipleOwners.current),
    },
  ];

  useEffect(() => {
    document.title = t("logo_title") + " - " + popupAddress.current;
  }, [popupAddress, t]);

  return (
    <Stack className={classes.popupBox}>
      <Group position="apart">
        <Text fw={700}>{popupAddress.current}</Text>
        <PopupOwner
          popupOwner={popupOwner}
          popupMultipleOwners={popupMultipleOwners}
        />
      </Group>
      <DataTable
        styles={(theme) => ({
          root: {
            height: mediaQuery ? "100%" : "calc(50vh - 150px)",
          },
        })}
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        idAccessor="unit"
        noRecordsText={t("popup.no_rent")}
        shadow="sm"
        records={sortedRecords}
        columns={essential_columns}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        rowStyle={({ unit_problem }) =>
          unit_problem ? { color: PROBLEM_COLOR } : undefined
        }
      />
    </Stack>
  );
}

export function Unit() {
  const { popupAddress, unitData, unitRents } = useContext(mapContext);
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "rentdate",
    direction: "desc",
  });
  const [sortedRecords, setRecords] = useState(sortBy(unitRents, "rentdate"));
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const mediaQuery = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
  const { search } = useLocation();
  const { t, i18n } = useTranslation();

  const RCInfo = ({ increase, from_date, to_date, threshold }) => {
    const infoText = (
      <Stack className={classes.rcText}>
        <Text>
          <Trans i18nKey="rent_increase.increase">
            This rent increase represents a cumulative increase of{" "}
            <b>{{ increase: increase }}</b> from {{ from_date: from_date }} to{" "}
            {{ to_date: to_date }}.
          </Trans>
        </Text>
        <Text>
          <Trans i18nKey="rent_increase.maximum">
            On {{ to_date: to_date }}, the maximum allowed rent increase was
            only <b>{{ threshold: threshold }}</b> under Culver City Municipal
            Code.
          </Trans>
        </Text>
        <Text>{t("rent_increase.vacancies")}</Text>
      </Stack>
    );

    const InfoIcon = forwardRef((props, ref) => (
      <ThemeIcon variant="outline" ref={ref} {...props}>
        <IconInfoCircle
          ref={ref}
          size={16} // set custom `width` and `height`
        />
      </ThemeIcon>
    ));

    return (
      <HoverCard shadow="md" width={300} withinPortal>
        <HoverCard.Target sx={{ cursor: "help" }}>
          <InfoIcon />
        </HoverCard.Target>
        <HoverCard.Dropdown>{infoText}</HoverCard.Dropdown>
      </HoverCard>
    );
  };

  useEffect(() => {
    const data = sortBy(unitRents, sortStatus.columnAccessor);
    setRecords(sortStatus.direction === "desc" ? data.reverse() : data);
  }, [sortStatus, unitRents]);

  useEffect(() => {
    document.title = t("logo_title") + " - " + popupAddress.current + ", " + t("popup.unit") + ": " + unitData.unit;
  }, [popupAddress, unitData, t]);

  const hist_columns = [
    {
      accessor: "rentdate",
      title: t("table_headers.date"),
      textAlignment: "right",
      sortable: true,
    },
    {
      accessor: "status",
      title: t("table_headers.vacancy"),
      sortable: true,
      hidden: !mediaQuery,
      textAlignment: "right",
      render: ({ status }) => {
        if (status === "Rented") {
          return <Badge>{t("chips.rented")}</Badge>;
        } else {
          return <Badge color="lime">{t("chips.vacant")}</Badge>;
        }
      },
    },
    {
      accessor: "rent",
      title: t("table_headers.rent"),
      sortable: true,
      textAlignment: "right",
      render: ({ rent }) =>
        new Intl.NumberFormat(i18n.language, {
          style: "currency",
          currency: "USD",
        }).format(rent),
    },
    {
      accessor: "perc_increase",
      title: t("table_headers.change"),
      sortable: true,
      textAlignment: "right",
      render: ({
        perc_increase,
        date_problem,
        PastRentReportDate_y,
        cumulative_increase,
        threshold,
        rentdate,
      }) => {
        if (perc_increase == null) return <Divider />;
        const number = Number(perc_increase).toLocaleString(i18n.language, {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        var numberArrow = <></>;

        if (perc_increase > 0) {
          const arrow = <IconArrowBadgeUp size={16} />;
          numberArrow = (
            <>
              <Text>
                <Text span color="red.8">
                {arrow}
                </Text>
                <Text span>{number}</Text>
              </Text>
            </>
          );
        } else if (perc_increase < 0) {
          const arrow = <IconArrowBadgeDown size={16} />;
          numberArrow = (
            <>
              <Text>
                <Text span color="green.8">
                {arrow}
                </Text>
                <Text span>{number}</Text>
              </Text>
            </>
          );
        } else {
          numberArrow = <>{number}</>;
        }

        return (
          <>
            {date_problem ? (
              <Group position="apart" spacing="xs">
                <RCInfo
                  increase={cumulative_increase}
                  from_date={PastRentReportDate_y}
                  to_date={rentdate}
                  threshold={threshold}
                />
                <Text>{numberArrow}</Text>
              </Group>
            ) : (
              <Text>{numberArrow}</Text>
            )}
          </>
        );
      },
    },
  ];

  if (!unitData || !unitRents) return <></>;

  return (
    <>
      <Stack className={classes.popupBox}>
        <Group position="apart">
          <Box>
            <Text fw={700}>{popupAddress.current}</Text>
            <Text fw={700}>
              {t("popup.unit")}: {unitData.unit}
            </Text>
          </Box>
          <Button
            component={Link}
            to={"../" + unitData.slug + search}
            variant="outline"
            leftIcon={<IconArrowNarrowLeft />}
          >
            {t("popup.back_to")} {popupAddress.current}
          </Button>
        </Group>
        <DataTable
          styles={(theme) => ({
            root: {
              height: mediaQuery ? "100%" : "calc(50vh - 185px)",
            },
          })}
          withBorder
          borderRadius="sm"
          withColumnBorders
          striped
          highlightOnHover
          noRecordsText={t("popup.no_hist")}
          shadow="sm"
          columns={hist_columns}
          records={sortedRecords}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
          rowStyle={({ date_problem }) =>
            date_problem ? { color: PROBLEM_COLOR } : undefined
          }
        />
      </Stack>
    </>
  );
}
