import React, { useState } from "react";
import {
  MantineProvider,
  ColorSchemeProvider,
  createStyles,
  Box,
} from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import Map from "./components/Map";
import { MapProvider } from "./context/mapContext";
import { Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import HeaderMenu from "./components/Header";
import { PrivacyPolicy } from "./components/PrivacyPolicy";

const HEADER_HEIGHT = 60;   // height of title header (how high you *want* it to be)
const FILTER_HEIGHT = 110;  // height of filter controls (must be manually calculated and entered here)
const PADDING = 10;         // 

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
    boxSizing: "border-box",
  },

  body: {
    display: "flex",
    boxSizing: "border-box",
  },

  main: {
    flex: 1,
    boxSizing: "border-box",
  },

  map: {
    //width: `calc(100vw - ${NAVBAR_WIDTH}px)`,
    width: `100vw`,
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,

    [theme.fn.smallerThan("sm")]: {
      height: "100vh",
      width: "100vw",
    },
  },

  header: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  filters: {
    position: "absolute",
    left: PADDING,
    [theme.fn.largerThan("sm")]: {
      top: HEADER_HEIGHT + PADDING,
    },
    [theme.fn.smallerThan("sm")]: {
      top: PADDING,
    },
  },

  stats: {
    position: "absolute",
    top: HEADER_HEIGHT + PADDING + FILTER_HEIGHT,
    left: PADDING,
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  colorToggle: {
    position: "absolute",
    right: 11,
    top: 145,
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  aboutButton: {
    position: "absolute",
    right: 11,
    top: 182,
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function App() {
  const preferredColorScheme = useColorScheme("dark");
  const [colorScheme, setColorScheme] = useState(preferredColorScheme);
  const toggleColorScheme = () =>
    setColorScheme(colorScheme === "dark" ? "light" : "dark");
    
  const update_date = "July 27, 2024";

  const { classes } = useStyles();

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            colorScheme,
            headings: { fontFamily: "Greycliff CF, sans-serif" },
            primaryColor: "grape",
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <MapProvider>
            <Box className={classes.root}>
              <HeaderMenu height={HEADER_HEIGHT} className={classes.header} update_date={update_date} />

              <Routes>
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/*"
                  element={
                    <div className={classes.body}>
                      <main className={classes.main}>
                        <Map className={classes.map} classes={classes} update_date={update_date} />
                      </main>
                    </div>
                  }
                />
              </Routes>
            </Box>
          </MapProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </QueryParamProvider>
  );
}
