import {
  ActionIcon,
  Modal,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconInfoCircle, IconBuildingEstate } from "@tabler/icons-react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const About = ({ update_date }) => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ maxWidth: 700 }}>
      <Title order={2} color="grape.9">
        <IconBuildingEstate /> {t("logo_title")}
      </Title>
      <Text>
        <a
          rel="external noopener noreferrer"
          target="_blank"
          href="https://medium.com/@hughfitzgerald/rents-charged-by-landlords-should-be-made-public-lets-start-with-culver-city-d224b7740b06"
        >
          Rents charged by landlords should be made public — Let's start with
          Culver City...
        </a>
      </Text>
      <Text>
        <Trans i18nKey="data_date">
          Data Updated: {{ update_date: update_date }}
        </Trans>
      </Text>
      <Text>{t("about.unauthorized")}</Text>
      <Text>
        <Trans i18nKey="about.author">
          This interactive Culver City rental registry map was developed by
          Culver City resident Stephen Jones, in conjunction with{" "}
          <a
            rel="external noopener noreferrer"
            target="_blank"
            href="https://cc4mh.org/"
          >
            Culver City for More Homes
          </a>
          . The information in the map was collected by the City of Culver City
          directly from landlords. We obtained the data from the city via public
          records requests. We owe city staff a debt of gratitude for responding
          to our numerous public records requests and for taking the time to
          help us better understand this data.
        </Trans>
      </Text>
      <Text fw={700}>{t("about.disclaimer")}</Text>
      <Text>
        <Trans i18nKey="about.privacy_policy">
          Read our{" "}
          <Link to="PrivacyPolicy" rel="noopener" target="_blank">
            Privacy Policy
          </Link>{" "}
          for more information about how we collect and use data.
        </Trans>
      </Text>
      <Text>
        <Trans i18nKey="about.questions">
          Questions? You can reach us at{" "}
          <a href="mailto:info@ccrentals.org">info@ccrentals.org</a>.
        </Trans>
      </Text>
    </Stack>
  );
};

export const AboutButton = ({ variant, className, size, update_date }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal
        title="About"
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        size="auto"
      >
        <About update_date={update_date} />
      </Modal>

      <ActionIcon className={className} onClick={() => setOpened(true)}>
        <ThemeIcon variant={variant} size={size}>
          <IconInfoCircle size={18} />
        </ThemeIcon>
      </ActionIcon>
    </>
  );
};
