import React, { useContext } from "react";
import { Text, SimpleGrid, Box } from "@mantine/core";
import { mapContext } from "../context/mapContext";
import { useTranslation } from "react-i18next";

const BigRentalStatBlock = ({ label, value }) => {
  return (
    <Box>
      <Text fz="xl" fw={700} align="left">
        {value}
        <Text fz="sm" fw={400} color="dimmed" align="left">
          {label}
        </Text>
      </Text>
    </Box>
  );
};

const RentalStats = () => {
  const { avgRent, minRent, maxRent, totalUnits } = useContext(mapContext);
  const { t, i18n } = useTranslation();

  const ar = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(avgRent);
  const mnr = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(minRent);
  const mxr = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(maxRent);
  const tu = new Intl.NumberFormat(i18n.language).format(totalUnits);

  return (
    <SimpleGrid cols={2}>
      <BigRentalStatBlock label={t("stats.num_units")} value={tu} />
      <BigRentalStatBlock label={t("stats.avg_rent")} value={ar} />

      <BigRentalStatBlock label={t("stats.low_rent")} value={mnr} />
      <BigRentalStatBlock label={t("stats.high_rent")} value={mxr} />
    </SimpleGrid>
  );
};

export default RentalStats;
